.demo-components-container {
    @include screen-tablet-above {
        width: calc(100% - 180px);
        background-color: $white;
        padding: 25px;
        border: 1px solid $border-color;
    }
}

.toc-affix {
    position: absolute;
    right: 25px;
    width: 150px;

    .ant-affix {
        z-index: 0;
    }

    .ant-anchor-wrapper {
        background-color: transparent;
        max-height: calc(100vh - 140px) !important;
    }

    @include screen-mobile {
        display: none;
    }
}

.code-boxes-col-1-1 {
    width: 100%;
}

.code-boxes-col-2-1 {
    display: inline-block;
    vertical-align: top;
}

.code-box {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 0 0 16px;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    transition: all 0.2s;

    .code-box-title {

        &,
        a {
            color: $gray-dark;
            background: $white;
        }
    }

    a.edit-button .anticon {
        color: $gray-dark;

        &:hover {
            color: $gray;
        }
    }

    &,
    .code-box-demo {
        background-color: $white;
    }

    .markdown {
        pre {
            margin: 0.5em 0;
            padding: 6px 12px;
        }

        pre code {
            margin: 0;
            background: #f5f5f5;
        }
    }

    &:target {
        border: 1px solid $brand-primary;
    }

    &-expand-trigger {
        position: relative;
        margin-left: 12px;
        color: #3b4357;
        font-size: 20px;
        cursor: pointer;
        opacity: 0.75;
        transition: all 0.3s;

        &:hover {
            opacity: 1;
        }

        .ant-row-rtl & {
            margin-right: 8px;
            margin-left: 0;
        }
    }

    &-title {
        position: absolute;
        top: -14px;
        margin-left: 16px;
        padding: 1px 8px;
        color: #777;
        background: $white;
        border-radius: $border-radius $border-radius 0 0;
        transition: background-color 0.4s;

        .ant-row-rtl & {
            margin-right: 16px;
            margin-left: 0;
            border-radius: $border-radius 0 0 $border-radius;
        }

        a,
        a:hover {
            color: $brand-primary;
            font-weight: 500;
            font-size: $font-size-base;
        }
    }

    &-description {
        padding: 18px 24px 12px;
    }

    a.edit-button {
        position: absolute;
        top: 7px;
        right: -16px;
        padding-right: 6px;
        font-size: 12px;
        text-decoration: none;
        background: inherit;
        transform: scale(0.9);

        .ant-row-rtl & {
            right: auto;
            left: -21px;
            padding-right: 0;
            padding-left: 6px;
        }
    }

    &-demo {
        padding: 42px 24px 50px;
        color: $body-color;
        border-bottom: 1px solid $border-color;
    }

    iframe {
        width: 100%;
        border: 0;
    }

    &-meta {
        &.markdown {
            position: relative;
            width: 100%;
            font-size: $font-size-base;
            border-radius: 0 0 $border-radius $border-radius;
            transition: background-color 0.4s;
        }

        blockquote {
            margin: 0;
        }

        h4,
        section & p {
            margin: 0;
        }

        >p {
            width: 100%;
            margin: 0.5em 0;
            padding-right: 25px;
            font-size: 12px;
            word-break: break-word;

            .ant-row-rtl & {
                padding-right: 0;
                padding-left: 25px;
            }
        }
    }

    &.expand &-meta {
        border-bottom: 1px dashed $border-color;
        border-radius: 0;
    }

    .code-expand-icon {
        cursor: pointer;
    }

    .code-expand-icon-show,
    .code-expand-icon-hide {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        margin: 0;
        box-shadow: none;
        transition: all 0.4s;
        user-select: none;

        .ant-row-rtl & {
            right: 0;
            left: auto;
        }
    }

    .code-expand-icon-show {
        opacity: 0.55;
        pointer-events: auto;

        &:hover {
            opacity: 1;
        }
    }

    .code-expand-icon.ant-tooltip-open .code-expand-icon-show {
        opacity: 1;
    }

    .code-expand-icon-hide {
        opacity: 0;
        pointer-events: none;
    }

    .highlight-wrapper {
        display: none;
        overflow: auto;
        border-radius: 0 0 $border-radius $border-radius;

        &-expand {
            display: block;
        }
    }

    .highlight {
        position: relative;

        .ant-tabs-nav-scroll {
            text-align: center;
        }

        pre {
            margin: 0;
            padding: 0;
            background: $white;
        }

        &:not(:first-child) {
            border-top: 1px dashed $border-color;
        }
    }

    &-actions {
        padding-top: 12px;
        text-align: center;
        border-top: 1px dashed $border-color;
        opacity: 0.7;
        transition: opacity 0.3s;

        &:hover {
            opacity: 1;
        }

        >i,
        >form,
        >span {
            position: relative;
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-left: 16px;
            vertical-align: top;

            .ant-row-rtl & {
                margin-right: 16px;
                margin-left: 0;
            }

            &:first-child {
                margin-left: 0;

                .ant-row-rtl & {
                    margin-right: 0;
                }
            }
        }

        >form {
            top: -2px;
        }
    }

    &-code-action {
        width: 20px;
        height: 20px;
        color: $gray;
        font-size: 16px;
        line-height: 18px;
        cursor: pointer;
        transition: all 0.24s;

        &:hover {
            color: $gray-dark;
        }
    }

    &-code-copy {
        width: 20px;
        height: 20px;
        color: $gray;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        background: $white;
        border-radius: 20px;
        cursor: pointer;
        transition: all 0.24s;

        &:hover {
            color: $gray-dark;
            transform: scale(1.2);
        }

        &.anticon-check {
            color: $brand-success !important;
            font-weight: bold;
        }
    }

    &-codepen {
        width: 20px;
        height: 20px;
        overflow: hidden;
        text-indent: -9999px;
        background: transparent url('https://gw.alipayobjects.com/zos/rmsportal/OtZslpOjYXijshDERXwc.svg') center / 14px no-repeat;
        border: 0;
        cursor: pointer;
        opacity: 0.65;
        transition: all 0.3s;

        &:hover {
            opacity: 1;
        }
    }

    &-riddle {
        display: none;
        width: 20px;
        height: 20px;
        overflow: hidden;
        text-indent: -9999px;
        background: transparent url('https://gw.alipayobjects.com/zos/rmsportal/DlHbxMCyeuyOrqOdbgik.svg') center / 14px no-repeat;
        border: 0;
        cursor: pointer;
        opacity: 0.65;
        transition: all 0.3s;

        &:hover {
            opacity: 1;
        }
    }

    &-codesandbox {
        width: 20px;
        height: 20px;
        overflow: hidden;
        text-indent: -9999px;
        background: transparent url('https://gw.alipayobjects.com/zos/rmsportal/aaYmtdDyHSCkXyLZVgGK.svg') center / 14px no-repeat;
        border: 0;
        cursor: pointer;
        opacity: 0.65;
        transition: all 0.3s;

        &:hover {
            opacity: 1;
        }
    }

    .highlight-wrapper:hover &-code-copy,
    .highlight-wrapper:hover &-codepen,
    .highlight-wrapper:hover &-codesandbox,
    .highlight-wrapper:hover &-riddle {
        opacity: 1;
    }

    pre {
        width: auto;
        margin: 0;

        code {
            background: $white;
            border: none;
        }
    }

    &-debug {
        border-color: $purple;
    }

    &-debug &-title a {
        color: $purple;
    }
}

.show-riddle-button .code-box-riddle {
    display: block;
}

.all-code-box-controls {
    float: right;

    .ant-row-rtl & {
        float: left;
    }
}

.ant-row-rtl {

    #components-tooltip-demo-placement,
    #components-popover-demo-placement,
    #components-popconfirm-demo-placement {
        .code-box-demo {
            direction: ltr;
        }
    }
}

label {
    &.api-type-label {
        padding: 1px 10px;
        font-size: 12px;
        border-radius: 4px;
        line-height: 18px;
        display: inline;
        vertical-align: middle;
        margin-left: 8px;
        font-weight: 400;
        text-transform: uppercase;
        border-radius: 4px;
        color: rgba(255, 255, 255, 0.85);

        &.directive {
            color: #eb2f96;
            border: 2px solid #eb2f96;
        }

        &.component {
            color: $brand-primary;
            border: 2px solid $brand-primary;
        }

        &.service {
            color: #52c41a;
            border: 2px solid #52c41a;
        }

        &.plugins {
            color: #841ac7;
            border: 2px solid #841ac7;
        }
    }
}
