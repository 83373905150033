
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$enlink-primary: mat-palette($mat-indigo);
$enlink-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$enlink-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$enlink-theme: mat-light-theme((
  color: (
    primary: $enlink-primary,
    accent: $enlink-accent,
    warn: $enlink-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($enlink-theme);

@import url("../node_modules/ng-zorro-antd/src/ng-zorro-antd.min.css");
@import url("../node_modules/@fortawesome/fontawesome-free/css/all.min.css");
@import "./assets/scss/app.scss";

.ant-menu-inline .ant-menu-item {
  padding: 0 16px 0 21px !important;
}

.ant-tabs-nav {
  margin: 0 !important;
}

.ant-tabs-content-holder {
  padding: 16px !important;
}
.ant-drawer-wrapper-body {
  height: 95% !important;
}
.ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label, .ant-form-vertical .ant-form-item-label{
  padding: 0 0 0 !important;
}

.vehicle-plate {
  padding: 5px 7px 5px 22px;
  display: inline-block;
  border: 2px solid #000;
  border-radius: 3px;
  position: relative;
  font-weight: bold;
    color: #000;
    font-size: 13px;

  &::before {
    content: "TR";
    display: flex;
    align-items: flex-end;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #1a56b1;
    color: #fff;
    padding: 0 3px 3px;
    font-size: 10px;
    font-weight: normal;
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
