@import 'variables';
@import 'mixins';
@import 'reset';


/*******************/
/*** Components ***/
/*****************/

/*General*/
@import 'components/general/typorgraphy';
@import 'components/general/buttons';
@import 'components/general/icons';

/*Layout*/
@import 'components/layout/breakpoint';
@import 'components/layout/grid';

/*Navigation*/
@import 'components/navigation/_affix';
@import 'components/navigation/_breadcrumb';
@import 'components/navigation/dropdown';
@import 'components/navigation/menu';
@import 'components/navigation/pagination';
@import 'components/navigation/page-header';
@import 'components/navigation/steps';

/*Data Entry*/
@import 'components/data-entry/autocomplete';
@import 'components/data-entry/cascader';
@import 'components/data-entry/checkbox';
@import 'components/data-entry/date-picker';
@import 'components/data-entry/form';
@import 'components/data-entry/input';
@import 'components/data-entry/mention';
@import 'components/data-entry/radio';
@import 'components/data-entry/rate';
@import 'components/data-entry/select';
@import 'components/data-entry/slider';
@import 'components/data-entry/switch';
@import 'components/data-entry/time-picker';
@import 'components/data-entry/transfer';
@import 'components/data-entry/tree-select';
@import 'components/data-entry/upload';

/*Data Display*/
@import 'components/data-display/avatar';
@import 'components/data-display/badge';
@import 'components/data-display/calendar';
@import 'components/data-display/card';
@import 'components/data-display/carousel';
@import 'components/data-display/collapse';
@import 'components/data-display/comment';
@import 'components/data-display/descriptions';
@import 'components/data-display/list';
@import 'components/data-display/popover';
@import 'components/data-display/statistic';
@import 'components/data-display/table';
@import 'components/data-display/tabs';
@import 'components/data-display/tag';
@import 'components/data-display/timeline';
@import 'components/data-display/tooltip';
@import 'components/data-display/tree';

/*Feedback*/
@import 'components/feedback/alert';
@import 'components/feedback/drawer';
@import 'components/feedback/message';
@import 'components/feedback/modal';
@import 'components/feedback/notifications';
@import 'components/feedback/progress';
@import 'components/feedback/spin';

/*Other*/
@import 'components/others/anchor';
@import 'components/others/back-to-top';
@import 'components/others/divider';

/******************/
/*** Template ****/
/****************/
@import 'template/header.scss';
@import 'template/layout.scss';
@import 'template/side-nav.scss';
@import 'template/footer.scss';

/*****************/
/***** Apps *****/
/***************/
@import 'apps/chat.scss';
@import 'apps/file-manager.scss';
@import 'apps/mail.scss';
@import 'apps/e-commerce.scss';

/*****************/
/*** Plugins ****/
/***************/
@import 'plugins/chartist.scss';
@import 'plugins/quill.scss';

/*******************/
/*** Utilities ****/
/*****************/
@import 'utilities/utilities-bs.scss';
@import 'utilities/utilities-enlink.scss';


/*******************/
/****** Demo ******/
/*****************/

@import 'demo/demo';