.ant-comment-content-author-name {
    color: $gray;
    font-weight: $font-weight-semibold;
}

.ant-comment-actions {
    >li {
        color: $gray;

        >span {
            color: $gray;
            font-size: $font-size-base;

            i {
                margin-right: 5px;
            }
        }
    }
}

.ant-comment-content-author-time {
    color: rgba($gray-dark, 0.5);
}

.ant-comment-avatar {

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}